import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { Observable ,  SubscriptionLike as ISubscription } from 'rxjs';
import { JsonPurchase } from '../../../core/model/purchase.model';
import { Store } from '@ngrx/store';
import { FormBuilder} from '@angular/forms';
import {AppState, GetPurchaseRangeCategorySuccess} from '../../../store';
import { FormatComponent } from '../format/format.component';
import { DeviceComponent } from '../device/device.component';
import { DiffusionComponent } from '../diffusion/diffusion.component';
import { PacingComponent } from '../pacing/pacing.component';
import { CappingComponent } from '../capping/capping.component';
import { BroadcastRangeComponent } from '../broadcast-range/broadcast-range.component';
import { CustomKeywordComponent } from '../custom-keyword/custom-keyword.component';
import { TargetingComponent } from '../targeting/targeting.component';
import { AppConstants } from '../../../app.constants';
import { PlanningComponent } from '../planning/planning.component';
import { DealIdComponent } from '../deal-id/deal-id.component';
import { MatDialog} from '@angular/material/dialog';
import { MatSnackBar} from '@angular/material/snack-bar';
import {ItemOfferComponent} from '../../shared/item-offer/item-offer.component';
import {PurchaseItemService} from '../../../core/service/purchase-item.service';
import {CustomKeywordService} from '../../../core/service/custom-keyword.service';
import {JsonStep} from '../../../core/model/step.model';
import {ProgrammaticDealComponent} from '../programmatic-deal/programmatic-deal.component';

const stepBlockCheckOffer = 3;

@Component({
  selector: 'app-accordion-display',
  templateUrl: './accordion-display.component.html',
  styleUrls: ['./accordion-display.component.scss']
})
export class AccordionDisplayComponent implements OnInit, OnChanges {

  public purchase$: Observable<JsonPurchase>;
  public display$: Observable<any>;
  public step;

  public doNotSave = false;
  
  public previousFormat = '';
  public currentFormat = '';
  public deleteRange = false;
  public isYoutubeFormat: boolean = false;
  public purchaseStep: JsonStep;
  public dealType = 0;

  @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() dealTypeChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(FormatComponent, {static: false}) format: FormatComponent;
  @ViewChild(DeviceComponent, {static: false}) device: DeviceComponent;
  @ViewChild(DiffusionComponent, {static: false}) diffusion: DiffusionComponent;
  @ViewChild(CappingComponent, {static: false}) capping: CappingComponent;
  @ViewChild(PacingComponent, {static: false}) pacing: PacingComponent;
  @ViewChild(BroadcastRangeComponent, {static: false}) broadcastRange: BroadcastRangeComponent;
  @ViewChild(TargetingComponent, {static: false}) targeting: TargetingComponent;
  @ViewChild(CustomKeywordComponent, {static: false}) keywordComp: CustomKeywordComponent;
  @ViewChild(PlanningComponent, {static: false}) planningComp: PlanningComponent;
  @ViewChild(ItemOfferComponent, {static: false}) itemOffer: ItemOfferComponent;
  @ViewChild(DealIdComponent, {static: false}) deal: DealIdComponent;
  @ViewChild(ProgrammaticDealComponent, {static: false}) programmaticDeal: ProgrammaticDealComponent;
  @Input() purchaseItemId: number = null;
  @Input() dispoType: number = AppConstants.typeOps;
  @Input() currentStep: number;
  @Input() loading: boolean = false;

  constructor(private _fb: FormBuilder,
              private store: Store<AppState>,
              public dialog: MatDialog,
              private snackBar: MatSnackBar,
              private purchaseItemService: PurchaseItemService,
            ) { }

  ngOnInit() {
    this.currentStep =  Number(this.currentStep);
    this.purchase$ = this.store.select('currentPurchase');
    this.display$ = this.store.select(state => {
      let items: any = null;
      this.currentFormat = state.purchaseItemRef.format;

      if (this.dispoType == AppConstants.typeDisplay) {
        items = state.currentPurchaseItems.display;
      } else if (this.dispoType == AppConstants.typeVideo) {
        items = state.currentPurchaseItems.video;
      }

      if (this.purchaseItemId && items.length > 0) {
        for (const p of items) {
          if (p.id == this.purchaseItemId) { return p; }
        }
      }

      return null;
    });

    this.purchase$
      .subscribe(purchase => {
        this.purchaseStep = purchase.step;
        this.dealType = purchase.dealType;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('purchaseItemId')) {
      if (changes.purchaseItemId.currentValue &&
        changes.purchaseItemId.previousValue != changes.purchaseItemId.currentValue) {
        this.keywordComp.loadCustomKeywordsFromAPI(changes.purchaseItemId.currentValue);

        if (this.purchaseItemId && (this.dispoType === AppConstants.typeVideo)) {
          this.purchaseItemService.checkIfYoutube(this.purchaseItemId)
            .subscribe((data) => {
              if (data.isYoutube) {
                this.isYoutubeFormat = !!data.isYoutube;
              }
            });
        }
      }
    }
  }

  setStep(index: number) {
    this.step = index;
    this.displayShowForm();
  }

  setCurrentStep(index: number) {
    this.currentStep = index;
    this.step = index;
    this.displayShowForm();
  }

  /**
   * Show next step
   */
  nextStep(): void {
    this.step++;
    this.currentStep++;
    this.displayShowForm();
  }

  cancelStep(): void {
    this.saveEvent.emit({
      nextEvent: 'cancel'
    });
  }

  displayShowForm(): void {
    for (let i = 0; i < 4; i++) {
      this['showForm' + i] = false;
      if (i == this.currentStep) {
        this['showForm' + i] = true;
      }
    }
  }

  // Save Formats And Diffusion Modalities
  saveData() {
    this.saveDiffusion('nextStep');
  }

  saveStep(): void {
    switch (this.step) {
      case 0:
        this.saveDiffusion('return');
        break;
      case 1:
        this.saveBroadcastRangeData('return');
        break;
      case 2:
        this.saveTargetingAndKeywordsData('return');
        break;
      case 3:
        this.saveDayparts('return');
        break;
    }
  }

  /**
   * Save diffusion params
   * @param {string} nextEvent
   */
  private saveDiffusion(nextEvent: string): void {
    if ((this.purchaseStep && this.purchaseStep.step_num < stepBlockCheckOffer && !this.itemOffer.isValid())
      || this.format.getFormats().length === 0) {
      return;
    }
    this.store.select(state => {
      let items: any = null;
      this.currentFormat = state.purchaseItemRef.format;
      if (this.dispoType == AppConstants.typeDisplay) {
        items = state.currentPurchaseItems.display;
      } else if (this.dispoType == AppConstants.typeVideo) {
        items = state.currentPurchaseItems.video;
      }

      if (this.purchaseItemId && items.length > 0) {
        for (const p of items) {
          if (p.id == this.purchaseItemId) { return p; }
        }
      }
    }).subscribe( res => {
      if (res && res.formats) { this.previousFormat = res.formats[0].codFormat.id; }
    });

    this.store.dispatch(new GetPurchaseRangeCategorySuccess([]));
    this.diffusionSaveEvent(nextEvent);
  }

  // Save Broadcast Range
  public saveBroadcastRangeData(event: string = null): void {
    if (this.broadcastRange.getBroadcastRange()) {
      this.saveEvent.emit({
        broadcastRange: this.broadcastRange.getBroadcastRange(),
        nextEvent: event,
        method: 'patch'
      });
    }
  }

  /**
   * @param nextEvent
   * @param deleteRange
   */
  diffusionSaveEvent(nextEvent, deleteRange: boolean = false): void {
    const diffusionEvent = {
      format: this.format.getFormats(),
      // device: this.device.getDevices(), // TODO: uncomment this line
      capping: this.capping.getCappings(),
      diffusionMode: this.diffusion?.diffusionForm.value.diffusionMode,
      pacing: this.pacing ? this.pacing.getPacings() : null,
      itemOffer: this.itemOffer.getSelectedItemOffer(),
      itemOfferTemplate: this.itemOffer.getSelectedItemOfferTemplate(),
      nextEvent: nextEvent
    };

    // programmatic deal case.
    if (this.dealType === 1) {
      this.saveEvent.emit({
        ...diffusionEvent,
        dealProgrammatic: this?.programmaticDeal.dealProgrammaticForm.getRawValue()
      });
    } else {
      this.saveEvent.emit(  {
        ...diffusionEvent,
        dealId: this.deal && this.deal.dealForm && this.deal.dealForm.value ? this.deal.dealForm.value.dealId : null
      });
    }
  }

  /**
   * Save Targeting And Keywords
   * @param event
   */
  saveTargetingAndKeywordsData(event: string = null): void  {
    // Keywords are used uniquely for equativ, and not updated in purchase_item
    // So we call synchronizing here
    if (this.keywordComp) {
      this.keywordComp.synchronizeKeywords(this.purchaseItemId);
    }

    this.saveEvent.emit({
      targetings: this.targeting.getTargetings(),
      nextEvent: event,
      method: 'patch'
    });
  }

  /**
   * Save DayParts
   * @param event
   */
  saveDayparts(event: string = null): void {
    this.saveEvent.emit({
      userTimezone: this.planningComp.planningForm.value.userTimezone,
      method: 'patch',
      nextEvent: event
    });
  }

  public showKeywords(keywords: any[], type: number = 1): string {
    let str = '';
    if (keywords.length > 0) {
      for (const keyword of keywords) {
        if (keyword.equal === type) {
          str = str.concat(', ' + keyword.keywordValue.keywordValue);
        }
      }
    }
    return str.replace(',', '');
  }

  public isDisplayItem(): boolean {
    return (this.dispoType === AppConstants.typeDisplay);
  }

  public isVideoOrTvItem(): boolean {
    return (this.dispoType === AppConstants.typeVideo);
  }

  updateDealType(event) {
    this.dealTypeChangeEvent.emit(event);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpService } from './http.service';
import { ProductService } from './product.service';
import { CampaignService } from './campaign.service';
import { PurchaseService } from './purchase.service';
import { PurchaseItemService } from './purchase-item.service';
import { SiteService } from './site.service';
import { AdvertiserService } from './advertiser.service';
import { HydratorService } from './hydrator.service';
import { FormatService } from './format.service';
import { VersioningService} from './versioning.service';
import { TarifUniteService } from './tarif-unite.service';
import { CappingService } from './capping.service';
import { FwCappingService } from './fw-capping.service';
import { ReferenceService } from './reference.service';
import { TypeCategoryService } from './type-category.service';
import { PurchaseRangeCategoryService } from './purchase-range-category.service';
import { TypeTargetingService } from './type-targeting.service';
import { PurchaseTargetingCategoryService } from './purchase-targeting-category.service';
import { PurchaseItemRangeService } from './purchase-item-range.service';
import { CollectionRubriqueService } from './collection-rubrique.service';
import { VideosRubriquesService } from './videos-rubriques.service';
// import { FTP_AUTH_CONFIG, FtpAuthConfig, FtpAuthModule } from 'ftp-ng2-auth-module';
import { KeywordService } from './keyword.service';
import { CustomKeywordService } from './custom-keyword.service';
import { PurchaseItemTargetingService } from './purchase-item-targeting.service';
import { RegionService } from './region.service';
import { DiffusionModeService } from './diffusion-mode.service';
import { BillingModeService } from './billing-mode.service';
import { DiffusionFormatService } from './diffusion-format.service';
import { CommentService } from './comment.service';
import { AbatementService } from './abatement.service';
import { StepService } from './step.service';
import { FilmService } from './film.service';
import { AlertService } from './alert.service';
import { UtService } from './ut.service';
import { FsaService } from './fsa.service';
import { AudienceService } from './audience.service';
import { UploadService } from './upload.service';
import { EmissionService } from './emission.service';
import { VideoService } from './video.service';
import { SynchroService } from './synchro.service';
import { OrangeService } from './orange.service';
import { PageVideoService } from './page-video.service';
import { AdmanagerService } from './admanager.service';
import { AudSegmentService } from './aud-segment.service';
import { ItemOfferService} from './item-offer.service';
import { PackRuleVariableService } from './pack-rule-variable.service';
import { PackRuleCriteriaService } from './pack-rule-criteria.service';
import { PackRuleService } from './pack-rule.service';
import { PackListService } from './pack-list.service';
import { TypePackService } from './type-pack.service';
import { TargetService } from './target.service';
import { EvtService } from './evt.service';
import { CreativeIdService } from './creative-id.service';
import { PackDisplayTagsService } from './pack-display-tags.service';
import {TvCampaignService} from './tv-campaign.service';
import {WmPageRubriqueService} from './wm-page-rubrique.service';
import {PageRubriqueService} from './page-rubrique.service';
import {LiveTrackingService} from './live-tracking.service';
import { PackProgramService } from './pack-program.service';
import {FilmLiveService} from './film-live.service';
import {MogadorService} from './mogador.service';
import {ProductDigitalService} from './product-digital.service';
import {CroiseTvDigitalService} from './croise-tv-digital.service';
import {PacingModeService} from './pacing-mode.service';
import {RubriqueCaService} from './rubrique-ca.service';
import {DetailSpotByDayService} from './detail-spot-by-screen.service';
import {OfferService} from './offer.service';
import {ItemOfferSalesService} from './item-offer-sales.service';
import {ItemOfferTemplateService} from './item-offer-template.service';
import {CreativeManagementService} from './creative-management.service';
import {PacingService} from './pacing.service';
import {ItemOfferRangeCategoryService} from './item-offer-range-category.service';
import {MultispotPushService} from './multispot-push.service';
import {AdUnitService} from './ad-unit.service';
import { CloudiNessService } from './cloudi-ness.service';
import {KantarService} from "./kantar.service";
import {BillingLogsService} from "./billing-logs.service";
import {BuyerService} from './buyer.service';
import {MediaReplacementService} from './media-replacement.service';
import {DealTypeService} from './deal-type.service';


/*const AUTH_CONFIG: FtpAuthConfig = {
  ApiBaseUrl: environment.adspace_api_base_url,
  OpenIdClientId: environment.openid_client_id,
  OpenIdScope: environment.openid_scope,
  OpenIdAuthorizeEndpoint: environment.openid_authorize_endpoint
};*/

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  exports: [
  ],
  providers: [
    AdvertiserService,
    HttpService,
    HydratorService,
    ProductService,
    CampaignService,
    SiteService,
    PurchaseService,
    AdvertiserService,
    FormatService,
    PurchaseItemService,
    ReferenceService,
    VersioningService,
    TarifUniteService,
    VersioningService,
    TypeCategoryService,
    PurchaseRangeCategoryService,
    CappingService,
    FwCappingService,
    TypeTargetingService,
    PurchaseTargetingCategoryService,
    PurchaseItemRangeService,
    KeywordService,
    CustomKeywordService,
    PurchaseItemTargetingService,
    RegionService,
    DiffusionModeService,
    BillingModeService,
    DiffusionFormatService,
    CommentService,
    AbatementService,
    StepService,
    FilmService,
    AlertService,
    UtService,
    FsaService,
    AudienceService,
    UploadService,
    EmissionService,
    VideoService,
    SynchroService,
    OrangeService,
    PageVideoService,
    AdmanagerService,
    AudSegmentService,
    ItemOfferService,
    ItemOfferTemplateService,
    OfferService,
    PackRuleVariableService,
    PackRuleCriteriaService,
    PackRuleService,
    PackListService,
    TypePackService,
    TargetService,
    EvtService,
    CreativeIdService,
    PackDisplayTagsService,
    TvCampaignService,
    WmPageRubriqueService,
    PageRubriqueService,
    LiveTrackingService,
    PackProgramService,
    FilmLiveService,
    MogadorService,
    ProductDigitalService,
    CroiseTvDigitalService,
    PacingModeService,
    RubriqueCaService,
    DetailSpotByDayService,
    ItemOfferSalesService,
    CreativeManagementService,
    PacingService,
    ItemOfferRangeCategoryService,
    MultispotPushService,
    AdUnitService,
    CloudiNessService,
    KantarService,
    BillingLogsService,
    VideosRubriquesService,
    // {provide: FTP_AUTH_CONFIG, useValue: AUTH_CONFIG}
    BuyerService,
    MediaReplacementService,
    DealTypeService,
  ]
})
export class ServiceModule { }

export const environment = {
  production: false,
  openid_client_id: '9B39DCDC05494A84',
  openid_scope: 'openid email profile groups',
  grant_type: 'authorization_code',
  response_type: 'code',
  auth_callback_path: '/login/callback',
  openid_authorize_endpoint: 'https://portailacces-rct.francetvpub.fr/IdPOAuth2/authorize/ilexoidc',
  parrainage_api_base_url: 'https://api.recette.adspace.francetvpub.fr',
  digital_api_base_url: 'https://digital-recette-api.francetvpub.fr',
  adspace_api_base_url: 'https://digital-recette-api.francetvpub.fr',
};

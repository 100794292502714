export const AppConstants = {
  snackBarDuration: 4000,
  typeOps: 2,
  typeVideo: 0,
  typeDisplay: 1,
  typeYoutube: 2,
  typeSegmentalTv: 3,
  typeDonationTechCost: 4,
  iptv: 'IP TV',
  impressions: 'impressions',
  keyword: {
    predefined: 'PREDEFINED',
    free: 'FREEFORM',
  },
  detailBillingMode: 2,
  forecast: {
    fw_sync_done: 1,
    fw_sync_error: 0,
    fw_fc_sent: 1,
    fw_fc_in_progress: 2,
    fw_fc_done: 3,
    fw_fc_error: 4,
    dfp_fc_error: 4,
    dfp_fc_done: 5
  },
  discounts: {
    ncw: 'NCW',
    gm: 'GM'
  },
  sponsorship: {
    video: 4,
    display: 1
  },
  alertLevel: {
    warning: 'WARNING',
    error: 'IMPORTANT',
  },
  tagTypeVideo: 'video',
  tagTypeDisplay: 'display',
  tagTypeSegmentalTv: 'segmental_tv',
  syncPacks: {
    status_started: 0,
    status_success: 2,
    status_error: 3
  },
  typeSitesDonation: 1,
  typeSitesTechCost: 2,
  defaultPurchaseStep: 1,

  selectionType: {
    included: 'INCLUDE',
    limited: 'FILTER',
    excluded: 'EXCLUDE'
  },
  typeTargetingPostalCode: 'DG_POSTAL_CODE',
  typeTargetingFormerRegion: 'DG_FORMER_REGION',
  typeTargetingRegion: 'DG_REGION',
  typeTargetingWMRegion: 'WM_REGION',

  // programmatic
  isProgrammatic: 1, 
};
